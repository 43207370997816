import styled, { css } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { CustomParagraph } from "../../../global/slate/slateTypes";
import composeCss from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";
import { IBoxTextSettings } from "./StyledText.types";

export const EDITOR_INITIAL_VALUE: CustomParagraph[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "text",
      },
    ],
    align: "left",
  },
];

export const generateBoxTextStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxTextSettings["$settings"];
  theme: IThemeState;
}) => {
  if (!$settings) return;
  return css`
    color: ${convertColorIdToHex($settings.text.color, theme.colorPalette)};
    ${composeCss.font($settings.font, theme.typography)}
    padding: ${$settings.padding.top}px ${$settings.padding.right}px ${$settings
      .padding.bottom}px ${$settings.padding.left}px;
  `;
};

export const StyledBoxText = styled.div<IBoxTextSettings>((props) =>
  generateBoxTextStyles(props)
);
