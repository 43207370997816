import styled from "styled-components";
import {
  IBoxBaseSettings,
  IElementTextContent,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../composeCss";
import { convertColorIdToHex } from "../../settingsPatterns.methods";

const StyledRichText = styled(ConditionalHTMLElement).attrs((props) => ({
  show: props?.$settings?.show,
}))<IBoxBaseSettings<IElementTextContent>>(
  ({ $settings, theme }) => `
  color: ${convertColorIdToHex($settings.text.color, theme.colorPalette)};
  ${composeCss.font($settings.font, theme.typography)}

  p:empty::after, p *:empty::after {
    content: "\\200b";
    visibility: hidden;
  }

  p.left {
    text-align: left;
  }

  p.center {
    text-align: center;
  }

  p.right {
    text-align: right;
  }

  p.justify {
    text-align: justify;
  }
`
);

export default StyledRichText;
